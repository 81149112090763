import * as React from "react";
import { Autocomplete, TextField, Box, Button } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { WithLoader, useDebounce } from "@packages/theme-mui-v5";
import { useImpersonationUser } from "../../hooks/useImpersonationUser";
import { People, useSearchPeople } from "@packages/service-api";

const SearchUser = () => {
  const [searchTerm, updateSearchTerm] = React.useState<string>("");
  const debounedSearchValue = useDebounce(searchTerm, 200);
  const { users, loading } = useSearchPeople(debounedSearchValue);
  const [user, setUser] = React.useState<People>(null);
  const { impersonationUser, loading: activating } = useImpersonationUser(false);

  const onAutoCompleteChange = (_, values: People) => {
    setUser(values);
  };

  const activateImpersonationUser = () => {
    impersonationUser(user.email);
  };

  return (
    <Box>
      <WithLoader loading={activating}>
        <Box>Search for user to impersonate</Box>
        <Autocomplete
          sx={{ width: 500 }}
          clearOnBlur={false}
          multiple={false}
          id="users"
          size="small"
          options={users}
          onChange={onAutoCompleteChange}
          onInputChange={(event: React.SyntheticEvent<Element, Event>, value: string) =>
            updateSearchTerm(value)
          }
          filterOptions={(options) => options}
          getOptionLabel={(option: People) => `${option.firstName} ${option.lastName}`}
          loading={loading}
          isOptionEqualToValue={(option, value) => option.personId === value.personId}
          ListboxProps={{
            style: {
              maxHeight: "60vh"
            }
          }}
          renderOption={(props, option) => (
            <li {...props} key={option.personId}>
              <span>{`${option.firstName} ${option.lastName} (${option.userName})`}</span>
            </li>
          )}
          renderInput={(params) => (
            <Box pt={1}>
              <TextField
                {...params}
                placeholder="Search users"
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {loading ? <CircularProgress size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  )
                }}
              />
            </Box>
          )}
        />
        <Button
          disabled={activating || !user}
          variant="contained"
          color="primary"
          onClick={() => activateImpersonationUser()}
        >
          Activate Impersonation
        </Button>
      </WithLoader>
    </Box>
  );
};

SearchUser.defaultProps = {
  multiple: false
};

export default SearchUser;
