import React from "react";
import { Box, Button } from "@mui/material";
import { useEndImpersonationUser } from "../../hooks/useEndImpersonationUser";
import { useImpersonationUser } from "../../hooks/useImpersonationUser";
import SearchUser from "./SearchUser";
import { Auth, useUserProfile } from "@packages/service-api";
import { WithLoader, useCountDown } from "@packages/theme-mui-v5";

type CountdownProps = {
  time: string;
};

const CountDown: React.FC<CountdownProps> = (props) => {
  const { days, hours, minutes, seconds } = useCountDown(props.time);

  React.useEffect(() => {
    if (days + hours + minutes + seconds <= 0) {
      window.location.reload();
    }
  }, [days, hours, minutes, seconds]);

  const countDownInfo = React.useMemo(() => {
    return (
      <Box sx={{ pb: 3 }}>
        The current impersonation session will expire in{" "}
        <b>
          {hours} hours and {minutes} minutes
        </b>
      </Box>
    );
  }, [hours, minutes]);

  return countDownInfo;
};

const ImpersonationActive: React.FC = () => {
  const profile = useUserProfile();
  const { impersonationUser, loading } = useImpersonationUser(true);
  const { endImpersonationUser, loading: deleting } = useEndImpersonationUser();

  return (
    <Box>
      <WithLoader loading={loading || deleting}>
        <b>Impersonation Active</b>
        <Box sx={{ pt: 3, pb: 1 }}>
          You are current impersonating the the user <b>{profile?.email}</b>
        </Box>
        <CountDown time={Auth.impersonationExpiryTime} />
        <Box sx={{ display: "flex", gap: 2 }}>
          <Button
            disabled={loading || deleting}
            variant="contained"
            color="primary"
            onClick={() => impersonationUser(profile?.email)}
          >
            Extend Session
          </Button>
          <Button
            disabled={loading || deleting}
            variant="outlined"
            color="error"
            onClick={() => endImpersonationUser()}
          >
            End Session
          </Button>
        </Box>
      </WithLoader>
    </Box>
  );
};

const ImpersonateUser = () => {
  return Auth.isImpersonationActive ? <ImpersonationActive /> : <SearchUser />;
};

export default ImpersonateUser;
