import { useMutation } from "react-query";
import { useSnackbar } from "@packages/theme-mui-v5";
import { CommonDataApi } from "@packages/service-api";
import { useCallback } from "react";

export const useEndImpersonationUser = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { mutateAsync, isLoading, error, data, isSuccess } = useMutation(
    () => CommonDataApi.People.deleteImpersonatePeople(),
    {
      onSuccess: () => {
        enqueueSnackbar("User's impersonate session ended successfully.", {
          variant: "success"
        });
        window.location.reload();
      },
      onError: () => {
        enqueueSnackbar("Unfortunately, We are unable to save your changes. Please try again.", {
          variant: "error"
        });
      }
    }
  );

  const endImpersonationUser = useCallback(() => mutateAsync(), [mutateAsync]);

  return {
    data,
    error: error,
    loading: isLoading,
    isSuccess,
    endImpersonationUser
  };
};
