import { useMutation } from "react-query";
import { useSnackbar } from "@packages/theme-mui-v5";
import { Auth, CommonDataApi } from "@packages/service-api";
import { useCallback } from "react";

export const useImpersonationUser = (isExtendSession: boolean) => {
  const { enqueueSnackbar } = useSnackbar();
  const { mutateAsync, isLoading, error, data, isSuccess } = useMutation(
    (userIdOrEmail: string) => CommonDataApi.People.impersonatePeople(userIdOrEmail),
    {
      onSuccess: () => {
        enqueueSnackbar("User is impersonated successfully.", {
          variant: "success"
        });
        if (isExtendSession) {
          Auth.fetchUserProfile();
        } else {
          window.location.reload();
        }
      },
      onError: () => {
        enqueueSnackbar("Unfortunately, We are unable to save your changes. Please try again.", {
          variant: "error"
        });
      }
    }
  );

  const impersonationUser = useCallback(
    (userIdOrEmail: string) => mutateAsync(userIdOrEmail),
    [mutateAsync]
  );

  return {
    data,
    error: error,
    loading: isLoading,
    isSuccess,
    impersonationUser
  };
};
