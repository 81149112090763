import React, { useCallback } from "react";

import { ShiftInstanceWithDate } from "../../../types";
import { useSelectedLocation } from "../../auth";
import { shiftManager } from "../../../shifts";

export const useSelectedShift = (applicationId: string) => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [selectedShift, setSelectedShift] = React.useState<ShiftInstanceWithDate>();

  const { selectedLocation } = useSelectedLocation(applicationId);

  React.useEffect(() => {
    const getSelectedShift = async () => {
      try {
        setLoading(true);
        const selectedShiftInState = await shiftManager.selectedShift(
          applicationId,
          selectedLocation.siteId,
          selectedLocation.locationId
        );

        if (
          selectedShiftInState &&
          (selectedShiftInState.shiftId !== selectedShift?.shiftId ||
            selectedShiftInState.startDateTime !== selectedShift?.startDateTime)
        ) {
          setSelectedShift(selectedShiftInState);
        }
      } finally {
        setLoading(false);
      }
    };

    if (selectedLocation) {
      getSelectedShift();
      return shiftManager.subscribe(getSelectedShift);
    }
  }, [selectedLocation]);

  const selectShift = useCallback(
    (applicationId: string, siteId: string, locationId: string, shiftId: string, date: string) => {
      shiftManager.selectShift(applicationId, siteId, locationId, shiftId, date);
    },
    [shiftManager]
  );

  return {
    loading,
    selectShift,
    selectedShift
  };
};
