import React from "react";
import { Box, MenuItem, Select, Typography } from "@mui/material";
import { useUpdateUserPreferences, useUserProfile } from "@packages/service-api";
import { WithLoader } from "@packages/theme-mui-v5";

const PreferencesPanel: React.FC = () => {
  const userProfile = useUserProfile();

  const { updateUserPreferences, saving } = useUpdateUserPreferences();

  const renderLabelandValue = (label: string, value: string) => (
    <Box pb={2}>
      <Typography>
        <b>{label}</b>
      </Typography>
      <Typography>{value}</Typography>
    </Box>
  );

  const handleUpdatePreference = async (path: string, value: string | number) => {
    await updateUserPreferences({ ui: { [path]: value } });
  };

  return (
    <WithLoader loading={saving}>
      <Box sx={{ mb: 2 }}>
        {renderLabelandValue("Grid View Mode", "Display data grid content highly condensed")}
        <Select
          size="small"
          defaultValue="normal"
          value={userProfile.preferences?.ui?.gridViewMode}
          onChange={(e) => {
            handleUpdatePreference("gridViewMode", e.target.value);
          }}
        >
          <MenuItem value="normal">Normal</MenuItem>
          <MenuItem value="dense">Dense</MenuItem>
        </Select>
      </Box>
    </WithLoader>
  );
};

export default PreferencesPanel;
