import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { Box, ThemeProvider as MUIThemeProvider } from "@mui/material";

import { ThemeProvider, SnackbarProvider } from "@packages/theme-mui-v5";

import AppBar from "./AppBar/AppBar";
import SideBar from "./SideBar/SideBar";
import SidebarProvider from "./NavigationProvider/NavigationProvider";
import { ApiProvider } from "@packages/service-api";
import { initializeSentry } from "@packages/sentry";

import config from "../config";

initializeSentry(config.name);

const Navigation: React.FC = () => {
  return (
    <ApiProvider>
      <ThemeProvider provider={MUIThemeProvider}>
        <SnackbarProvider>
          <Router>
            <SidebarProvider>
              <Box>
                <AppBar />
                <SideBar />
              </Box>
            </SidebarProvider>
          </Router>
        </SnackbarProvider>
      </ThemeProvider>
    </ApiProvider>
  );
};

export default Navigation;
