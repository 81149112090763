import { useUserProfile } from "@packages/service-api";
import React from "react";
import { UserAccountTab } from "../UserProfileModal/UserProfileModal";

type State = {
  sideBarOpen: boolean;
  accountModalOpen: boolean;
  defaultAccountModalTab: UserAccountTab;
};

type NavigationState = {
  state: State;
  setState: (key: keyof State, nextValue: boolean | UserAccountTab) => void;
};

const initialState = {
  sideBarOpen: false,
  accountModalOpen: false,
  defaultAccountModalTab: undefined
};

export const NavigationContext = React.createContext<NavigationState>({
  state: initialState,
  setState: () => true
});

const NavigationProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [state, setState] = React.useState<State>(initialState);
  const userProfile = useUserProfile();

  React.useEffect(() => {
    if (userProfile) {
      if (
        !userProfile.preferences ||
        typeof userProfile.preferences !== "object" ||
        Object.keys(userProfile.preferences).length === 0
      ) {
        setState((s) => ({
          ...s,
          defaultAccountModalTab: "Site",
          accountModalOpen: true
        }));
      }
    }
  }, [userProfile]);

  return (
    <NavigationContext.Provider
      value={{
        state,
        setState: (key: keyof State, nextValue: boolean | UserAccountTab) =>
          setState((s) => ({ ...s, [key]: nextValue }))
      }}
    >
      {children}
    </NavigationContext.Provider>
  );
};

export default NavigationProvider;
