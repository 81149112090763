import { useCallback, useMemo } from "react";
import { dayjs } from "@packages/utils";

import { ShiftInstanceWithDate, ShiftWithInstance } from "../../../types";
import { shiftManager, ShiftManagerLocationStatus } from "../../../shifts";
import { useSelectedLocation } from "../../auth";

import { useCurrentShift } from "./useCurrentShift";
import { useLocationState } from "./useLocationState";
import { useSelectedShift } from "./useSelectedShift";

type ShiftsState = {
  /**
   * The current shift for the the selected location
   */
  currentShift: ShiftInstanceWithDate | null;
  /**
   * Does the current shift match the selected shift
   */
  currentShiftActive: boolean;
  /**
   * Error in the shift manager and the selected location
   */
  error: string | undefined;
  /**
   * Is data loading
   */
  loading: boolean;
  /**
   * The selected shift for the the selected location
   */
  selectedShift: ShiftInstanceWithDate | null;
  /**
   * Go back n number of shifts.
   * @param {number} [numOfShifts=1] - Number of shifts to go back
   */
  selectPreviousShift: (numOfShifts?: number) => void;
  /**
   * Go forward n number of shifts.
   * @param {number} [numOfShifts=1] - Number of shifts to go forward
   */
  selectNextShift: (numOfShifts?: number) => void;
  /**
   * Select specific shift
   * @param {string} shiftId - Id of shift to select
   * @param {string} date - date in YYYY-MM-DD format of shift to select
   */
  selectShift: (shiftId: string, date: string) => void;
  /**
   * Select specific shift
   * @param {string} shiftId - Id of shift to select
   * @param {string} date - date in YYYY-MM-DD format of shift to select
   * @returns {ShiftWithInstance[]} - Shifts that exist today
   */
  shiftsToday: ShiftWithInstance[];
  /**
   * The selected shift for the the selected location
   */
  status: ShiftManagerLocationStatus;
};

export const useShiftsState = (applicationId): ShiftsState => {
  const { selectedLocation, loading: selectedLocationLoading } = useSelectedLocation(applicationId);
  const { currentShift, loading: currentShiftLoading } = useCurrentShift(applicationId);
  const {
    selectShift,
    selectedShift,
    loading: selectedShiftLoading
  } = useSelectedShift(applicationId);
  const { state, loading: stateLoading } = useLocationState(applicationId);

  const loading =
    state?.status === "initializing" ||
    selectedLocationLoading ||
    currentShiftLoading ||
    selectedShiftLoading ||
    stateLoading;

  const shiftsToday = useMemo(() => {
    if (!state?.schedule || !selectedShift) {
      return [];
    }

    const shiftDate = dayjs(selectedShift.date);

    return state.schedule.shiftsInstances.reduce<ShiftWithInstance[]>((shifts, instance) => {
      if (
        dayjs(instance.startDateTime).isAfter(shiftDate.clone().startOf("day")) &&
        dayjs(instance.startDateTime).isBefore(shiftDate.clone().endOf("day"))
      ) {
        const shift = state.schedule.shiftRotation.shifts?.find(
          (shift) => shift.shiftId === instance.shiftId
        );

        if (shift) {
          shifts.push({
            ...shift,
            instance
          });
        }
      }

      return shifts;
    }, []);
  }, [state, selectedShift]);

  const currentShiftActive = useMemo(() => {
    if (!currentShift || !selectedShift) {
      return false;
    }

    return (
      currentShift?.date === selectedShift?.date && currentShift?.shiftId === selectedShift?.shiftId
    );
  }, [selectedShift, currentShift]);

  const selectNextShift = useCallback(
    (numOfShifts: number) => {
      shiftManager.progressShiftIndex(
        applicationId,
        selectedLocation?.siteId,
        selectedLocation?.locationId,
        numOfShifts
      );
    },
    [shiftManager, selectedLocation]
  );

  const selectSpecificShift = useCallback(
    (shiftId: string, date: string) => {
      if (selectedLocation) {
        selectShift(
          applicationId,
          selectedLocation.siteId,
          selectedLocation.locationId,
          shiftId,
          date
        );
      }
    },
    [selectedLocation]
  );

  return {
    currentShift,
    currentShiftActive,
    error: state?.error,
    loading,
    selectedShift,
    selectNextShift: useCallback(
      (numOfShifts = 1) => selectNextShift(numOfShifts),
      [selectNextShift]
    ),
    selectPreviousShift: useCallback(
      (numOfShifts = -1) => selectNextShift(numOfShifts),
      [selectNextShift]
    ),
    selectShift: (shiftId: string, date: string) => selectSpecificShift(shiftId, date),
    shiftsToday,
    status: state?.status
  };
};
