import React from "react";
import { List, ListItemIcon, ListItemText, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { ApplicationIcon, ApplicationIconName } from "@packages/theme-mui-v5";
import { Application, MenuItem } from "@nutrien-operations/config";
import { ListItem } from "../SideBarMenu/SideBarMenu";
import { WithPermissions } from "@packages/service-api";
import { Link } from "react-router-dom";
import ApiDocumentationLink from "./ApiDocumentationLink";

type Props = {
  applicationId: string;
  application: Application;
  menu: MenuItem;
  onNavigate: (path: string) => void;
};

const RouterLink = styled(Link)(() => ({
  color: "inherit",
  textDecoration: "inherit"
}));

const ApplicationMenu: React.FC<Props> = (props) => {
  const { application, applicationId, menu, onNavigate } = props;

  const renderChildren = (children: MenuItem[], depth: number, parentPath: string) =>
    children.map((ch, i) => {
      const path = parentPath === "/" ? `${ch.path}` : `${parentPath}${ch.path}`;
      const childRoute = parentPath === "/" ? "" : `${parentPath}${ch.path === "/" ? "" : "/"}`;
      return (
        <WithPermissions
          applicationId={applicationId}
          key={i}
          hideNoPermissionsUI
          permissions={ch.permission}
        >
          <RouterLink to={path}>
            <ListItem sx={{ pl: 7 + depth, borderBottom: "none" }}>
              <ListItemText
                primary={
                  <Typography sx={{ fontWeight: depth === 0 ? 700 : undefined }}>
                    {ch.label}
                  </Typography>
                }
                sx={{ ml: 2 }}
              />
            </ListItem>
            <List component="div" disablePadding>
              {ch.children && renderChildren(ch.children, depth + 1, childRoute)}
            </List>
          </RouterLink>
        </WithPermissions>
      );
    });

  return (
    <List component="nav" disablePadding>
      <ListItem onClick={() => onNavigate(menu.path)}>
        {menu.icon && (
          <ListItemIcon>
            <ApplicationIcon name={menu.icon as ApplicationIconName} />
          </ListItemIcon>
        )}
        <ListItemText primary={menu.label} />
        <ApiDocumentationLink application={application} />
      </ListItem>
      <List component="div" disablePadding>
        {menu.children && renderChildren(menu.children, 0, menu.path)}
      </List>
    </List>
  );
};

export default ApplicationMenu;
