import { useMutation } from "react-query";

import Auth from "../../auth/auth";
import { Preferences } from "../../types/auth";

export const useUpdateSelectedSite = () => {
  const { mutateAsync, isLoading } = useMutation({
    mutationFn: (selectedSiteId: string) =>
      Auth.updateUserPreferences({ selectedSiteId } as Partial<Preferences>)
  });

  return {
    saving: isLoading,
    updateSelectedSite: mutateAsync
  };
};
