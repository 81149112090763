import React, { useMemo } from "react";
import MenuIcon from "@mui/icons-material/Menu";

import {
  AppBar as MuiAppBar,
  AppBarProps as MuiAppBarProps,
  Box,
  Toolbar,
  Typography,
  IconButton,
  Tooltip,
  Avatar,
  Menu,
  MenuItem,
  styled,
  Button,
  CircularProgress
} from "@mui/material";
import { blue, yellow } from "@mui/material/colors";

import useNavigationState from "../../hooks/useNavigationState";
import { drawerOpenWidth, drawerClosedWidth } from "../SideBar/SideBar";
import MessageBus from "@packages/message-bus";
import { Auth, useUserProfile } from "@packages/service-api";
import { Person } from "@mui/icons-material";
import UserProfileModal from "../UserProfileModal/UserProfileModal";
import { ApplicationIcon, useCountDown } from "@packages/theme-mui-v5";
import config from "@nutrien-operations/config";
import { useEndImpersonationUser } from "../../hooks/useEndImpersonationUser";
import ShiftSelector from "../ShiftSelector";

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open"
})<AppBarProps>(({ theme, open }) => ({
  marginLeft: drawerClosedWidth,
  boxShadow:
    "0px 9px 12px rgba(0, 0, 0, 0.035), 0px 3px 16px rgba(0, 0, 0, 0.03), 0px 5px 6px rgba(0, 0, 0, 0.05)",
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  ...(open && {
    width: `calc(100% - ${drawerOpenWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  })
}));

const CountDown: React.FC = () => {
  const { days, hours, minutes, seconds } = useCountDown(Auth?.impersonationExpiryTime);

  React.useEffect(() => {
    if (days + hours + minutes + seconds <= 0) {
      window.location.reload();
    }
  }, [days, hours, minutes, seconds]);

  const countDownInfo = React.useMemo(() => {
    return (
      <b>
        {hours}h {minutes}m
      </b>
    );
  }, [hours, minutes]);

  return countDownInfo;
};

const NavBar: React.FC = () => {
  const { endImpersonationUser, loading: isEndingImpersonation } = useEndImpersonationUser();
  const profile = useUserProfile();
  const { state, showAccountModal, hideAccountModal, toggleSidebar } = useNavigationState();

  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);
  const [title, setTitle] = React.useState<string>("");

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleShowProfileClick = () => {
    showAccountModal();
    handleCloseUserMenu();
  };

  const handleUserSignout = async () => {
    if (state.sideBarOpen) {
      toggleSidebar();
    }

    handleCloseUserMenu();
    await Auth.signOut();
  };

  React.useEffect(() => {
    MessageBus.subscribe<{ title: string }>("app.navbar.title", (_message, { title }) => {
      setTitle(title);
    });
  }, [MessageBus]);

  const bgcolor = useMemo(() => {
    if (Auth.isImpersonationActive) {
      return "warning.light";
    }

    switch (config.environment) {
      case "development":
        return blue[100];
      case "localdev":
        return blue[100];
      case "uat":
        return yellow[100];
    }

    return "";
  }, [Auth.isImpersonationActive]);

  const environment = useMemo(() => {
    switch (config.environment) {
      case "development":
        return "Development";
      case "localdev":
        return "Local Development";
      case "uat":
        return "UAT";
    }

    return "";
  }, [config.environment]);

  return (
    <>
      <AppBar
        color="default"
        position="fixed"
        sx={{ backgroundColor: bgcolor }}
        open={state.sideBarOpen}
      >
        <Toolbar
          sx={{
            display: "flex",
            justifyContent: "space-between"
          }}
        >
          {profile && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center"
              }}
            >
              <IconButton
                onClick={toggleSidebar}
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                sx={{ mr: 2 }}
              >
                <MenuIcon />
              </IconButton>
              {environment && (
                <Typography
                  sx={{
                    fontWeight: "bold",
                    color: "common.black"
                  }}
                >
                  {environment}
                </Typography>
              )}
            </Box>
          )}

          <Box>
            <ShiftSelector
              fallback={<Typography variant="h4">{title || "Nutrien Operations"}</Typography>}
            />
          </Box>
          <Box sx={{ flexGrow: 0 }}>
            {profile ? (
              <Box sx={{ display: "flex", flexGrow: 1, flex: 1 }}>
                {Auth.isImpersonationActive && (
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Tooltip title="Impersonation Expiry time">
                      <Box
                        sx={{
                          color: "black",
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          mr: 1.5,
                          "& > *": {
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            mr: 0.5,
                            fontSize: 12
                          }
                        }}
                      >
                        <Box>
                          <ApplicationIcon name="clock" />
                        </Box>
                        <CountDown />
                      </Box>
                    </Tooltip>
                    <Tooltip title="End Impersonation">
                      <IconButton
                        size="small"
                        sx={{ color: "red", mr: 1.3 }}
                        onClick={() => endImpersonationUser()}
                      >
                        {isEndingImpersonation ? (
                          <CircularProgress size={16} />
                        ) : (
                          <ApplicationIcon name="exit" />
                        )}
                      </IconButton>
                    </Tooltip>
                  </Box>
                )}
                <Tooltip title="User Menu">
                  <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                    {profile ? (
                      <Avatar
                        alt={`${profile?.firstName} ${profile.lastName}`}
                        sx={
                          Auth.isImpersonationActive
                            ? {
                                color: "primary.main",
                                bgcolor: "primary.light"
                              }
                            : {}
                        }
                      >
                        {profile?.firstName[0]}
                        {profile?.lastName[0]}
                      </Avatar>
                    ) : (
                      <Avatar alt="User">
                        <Person />
                      </Avatar>
                    )}
                  </IconButton>
                </Tooltip>
                {!!Auth.isImpersonationActive && (
                  <Box sx={{ pl: 1 }}>
                    <Box sx={{ fontSize: 11, mb: -0.5 }}>IMPERSONATION ACTIVE</Box>
                    <Box sx={{ fontSize: 14 }} component="b">
                      {profile?.firstName || ""} {profile?.lastName || ""}
                    </Box>
                  </Box>
                )}
                <Menu
                  sx={{ mt: "45px" }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right"
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right"
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  <MenuItem onClick={handleShowProfileClick}>
                    <Typography textAlign="center">My Profile</Typography>
                  </MenuItem>
                  <MenuItem onClick={handleUserSignout}>
                    <Typography textAlign="center">Logout</Typography>
                  </MenuItem>
                </Menu>
              </Box>
            ) : (
              <Button onClick={() => Auth.signIn()}>Sign In</Button>
            )}
          </Box>
        </Toolbar>
      </AppBar>

      {state.accountModalOpen && (
        <UserProfileModal
          defaultTab={state.defaultAccountModalTab}
          onClose={() => {
            setAnchorElUser(null);
            hideAccountModal();
          }}
        />
      )}
    </>
  );
};

export default NavBar;
