import React from "react";
import {
  Box,
  Button,
  ButtonGroup,
  CircularProgress,
  IconButton,
  Tooltip,
  Typography
} from "@mui/material";

import { ApplicationIcon } from "@packages/theme-mui-v5";
import { ShiftConfiguration } from "@nutrien-operations/config";

import { dayjs } from "@packages/utils";
import { useShiftsState } from "@packages/service-api";

type Props = {
  applicationId: string;
  config: ShiftConfiguration;
};

const ShiftSelectorWrapper: React.FC<Props> = (props) => {
  const { applicationId, config } = props;

  const {
    currentShift,
    currentShiftActive,
    loading,
    status,
    error,
    selectedShift,
    selectNextShift,
    selectPreviousShift,
    selectShift,
    shiftsToday
  } = useShiftsState(applicationId);

  if (error || status === "not-enabled") {
    return null;
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        minWidth: 200
      }}
    >
      <Box>
        <IconButton color="primary" disabled={loading} onClick={() => selectPreviousShift()}>
          <ApplicationIcon name="chevron-left" />
        </IconButton>
      </Box>
      {loading || !selectedShift ? (
        <CircularProgress size={18} />
      ) : (
        <>
          {selectedShift && (
            <>
              <Box
                sx={{
                  display: "flex",
                  flexGrow: 1,
                  px: 1,
                  mt: 0,
                  flexDirection: "column",
                  justifyContent: "space-between",
                  alignItems: "center"
                }}
              >
                <Typography sx={{ pb: 0, fontSize: 15, mb: 0.5 }}>
                  {dayjs(selectedShift.startDateTime).format("ddd MMM, DD")}
                </Typography>

                <ButtonGroup
                  disableElevation
                  size="small"
                  sx={{
                    height: 22,
                    backgroundColor: "grey.100"
                  }}
                >
                  {shiftsToday?.map((shift) => {
                    const disabled = dayjs(shift.instance.startDateTime).isAfter(
                      currentShift.startDateTime
                    );
                    return (
                      <Button
                        disabled={currentShiftActive && disabled}
                        key={shift.shiftId}
                        onClick={() =>
                          selectShift(
                            shift.shiftId,
                            dayjs(shift.instance.startDateTime).format("YYYY-MM-DD")
                          )
                        }
                        sx={{
                          border: "none",
                          textTransform: "unset",
                          fontSize: 11
                        }}
                        variant={shift.shiftId === selectedShift.shiftId ? "contained" : undefined}
                      >
                        {shift.name}
                      </Button>
                    );
                  })}
                </ButtonGroup>
              </Box>
              <Tooltip title={`Current Shift - ${currentShiftActive ? "Yes" : "No"}`}>
                <IconButton
                  onClick={() => selectShift(currentShift.shiftId, currentShift.startDateTime)}
                  sx={{
                    color: currentShiftActive ? "common.white" : "primary.main",
                    backgroundColor: currentShiftActive ? "primary.main" : "grey.100",
                    mx: 1
                  }}
                >
                  <ApplicationIcon name="calendar-check" />
                </IconButton>
              </Tooltip>
            </>
          )}
        </>
      )}
      <Box>
        <IconButton
          color="primary"
          disabled={loading || (currentShiftActive && !config.canSeeFutureShifts)}
          onClick={() => selectNextShift()}
        >
          <ApplicationIcon name="chevron-right" />
        </IconButton>
      </Box>
    </Box>
  );
};

export default ShiftSelectorWrapper;
