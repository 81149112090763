import { UseQueryOptions, useQuery } from "react-query";
import { CommonDataApi, HTTPError, PeopleListResponse } from "../../..";
import { peopleOfLocation } from "./query-cache";

/**
 * Get people at a specified location
 */
export const useGetPeopleByLocationId = (applicationId, siteId, locationId) => {
  const queryOptions: UseQueryOptions<PeopleListResponse, HTTPError> = {
    queryKey: peopleOfLocation(applicationId, siteId, locationId),
    queryFn: () => CommonDataApi.People.getByLocation(applicationId, siteId, locationId),
    select: (data) => {
      if (!data.data) {
        return;
      }

      return {
        ...data,
        data: data.data.sort((a, b) =>
          a.firstName > b.firstName ? 1 : b.firstName > a.firstName ? -1 : 0
        )
      };
    },
    enabled: Boolean(locationId && siteId)
  };

  const { data, isLoading, isError, isSuccess } = useQuery(queryOptions);

  return {
    users: data,
    loading: isLoading,
    isError,
    isSuccess
  };
};
