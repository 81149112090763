import React from "react";
import { Box, CircularProgress, IconButton, styled, Typography, useTheme } from "@mui/material";
import { MoreVert } from "@mui/icons-material";

import useNavigationState from "../../hooks/useNavigationState";
import { useGetSites, useUserProfile } from "@packages/service-api";

const Wrapper = styled(Box)(({ theme }) => ({
  background: "#406580",
  height: 40,
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: theme.spacing(0, 2),

  "&> p": {
    color: theme.palette.common.white
  }
}));

const SiteSelector: React.FC = () => {
  const theme = useTheme();
  const { showAccountModal } = useNavigationState();
  const { loading: sitesLoading, sites } = useGetSites({}, { size: 100 });
  const userProfile = useUserProfile();

  const currentSite = React.useMemo(() => {
    return userProfile && sites.find((s) => s.siteId === userProfile.preferences?.selectedSiteId);
  }, [userProfile, sites]);

  return (
    <Wrapper>
      <Typography>
        {sitesLoading ? (
          <CircularProgress size={18} />
        ) : (
          currentSite?.siteName || "No site selected"
        )}
      </Typography>
      <IconButton onClick={() => showAccountModal("Site")} disabled={sitesLoading}>
        <MoreVert htmlColor={theme.palette.common.white} />
      </IconButton>
    </Wrapper>
  );
};

export default SiteSelector;
