import React from "react";

import { ShiftInstanceWithDate } from "../../../types";
import { useSelectedLocation } from "../../auth";
import { shiftManager } from "../../../shifts";

export const useCurrentShift = (applicationId: string) => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [currentShift, setCurrentShift] = React.useState<ShiftInstanceWithDate>();

  const { selectedLocation } = useSelectedLocation(applicationId);

  React.useEffect(() => {
    const getCurrentState = async () => {
      try {
        setLoading(true);

        const currentShiftInState = await shiftManager.currentShift(
          applicationId,
          selectedLocation.siteId,
          selectedLocation.locationId
        );

        if (
          currentShiftInState &&
          currentShiftInState.shiftId !== currentShift?.shiftId &&
          currentShiftInState.startDateTime !== currentShift?.startDateTime
        ) {
          setCurrentShift(currentShiftInState);
        }
      } finally {
        setLoading(false);
      }
    };

    if (selectedLocation) {
      getCurrentState();

      return shiftManager.subscribe(getCurrentState);
    }
  }, [selectedLocation]);

  return {
    currentShift,
    loading
  };
};
