import * as React from "react";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { Link } from "react-router-dom";
import { ApplicationGroup } from "@nutrien-operations/config";

import { styled } from "@mui/material/styles";
import { ApplicationIcon, ApplicationIconName } from "@packages/theme-mui-v5";
import { WithPermissions } from "@packages/service-api";

// TODO: Fix the any typing to get around type issue that is causing pnpm compilation to fail
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const ListItem: any = styled(ListItemButton)(({ theme }) => ({
  flexGrow: 1,

  display: "flex",
  borderBottom: `1px solid ${theme.palette.common.black}`,
  "& .MuiListItemIcon-root": {
    color: theme.palette.common.white,
    fontSize: 14
  },
  "& .MuiListItemText-root": {
    color: theme.palette.common.white,
    fontSize: 14
  }
}));

const RouterLink = styled(Link)(() => ({
  color: "inherit",
  textDecoration: "inherit"
}));

// TODO: Fix the any typing to get around type issue that is causing pnpm compilation to fail
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const StyledListHeader: any = styled(ListItemText)(({ theme }) => ({
  color: theme.palette.common.white,
  fontSize: 14,
  fontWeight: 700
}));

type Props = {
  applicationGroup: ApplicationGroup;
  onNavigate: (path: string) => void;
  initiallyCollapsed?: boolean;
};

const SideBarMenu: React.FC<Props> = ({ applicationGroup, initiallyCollapsed }) => {
  const [collapsed, setCollapsed] = React.useState<boolean>(initiallyCollapsed || true);

  return (
    <List component="nav">
      <ListItem onClick={() => setCollapsed((col) => !col)}>
        <ListItemIcon>
          <ApplicationIcon name={applicationGroup.icon as ApplicationIconName} />
        </ListItemIcon>
        <StyledListHeader disableTypography primary={applicationGroup.displayName} />
        {collapsed ? <ExpandLess htmlColor="white" /> : <ExpandMore htmlColor="white" />}
      </ListItem>
      <Collapse in={collapsed} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {applicationGroup.applications.map((app) => (
            <WithPermissions
              applicationId={app.applicationId}
              hideNoPermissionsUI
              key={app.applicationId}
              permissions={Object.values(app.permissions || {})}
            >
              <RouterLink to={app.path.value}>
                <ListItem sx={{ pl: 4 }}>
                  <ListItemIcon sx={{ mr: 0 }}>
                    <ApplicationIcon name={app.icon as ApplicationIconName} />
                  </ListItemIcon>
                  <ListItemText primary={app.displayName} />
                </ListItem>
              </RouterLink>
            </WithPermissions>
          ))}
        </List>
      </Collapse>
    </List>
  );
};

export default SideBarMenu;
