import React from "react";
import { styled } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";

import useNavigationState from "../../hooks/useNavigationState";
import { Application, groupedApplications, MenuItem } from "@nutrien-operations/config";
import { Box, List, ListItemIcon, ListItemText, Typography } from "@mui/material";
import SideBarMenu, { ListItem } from "../SideBarMenu/SideBarMenu";
import { Close } from "@mui/icons-material";
import SiteSelector from "../UserProfileModal/SiteSelector";
import { ApplicationIcon } from "@packages/theme-mui-v5";
import { useNavigate } from "react-router-dom";
import ApplicationMenu from "./ApplicationMenu";
import MessageBus from "@packages/message-bus";

export const drawerOpenWidth = 290;
export const drawerClosedWidth = 0;

const Drawer = styled(
  MuiDrawer,
  {}
)(({ theme }) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen
  }),
  overflowX: "hidden",
  "& .MuiDrawer-paper": {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    width: drawerOpenWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    }),
    overflowX: "hidden",
    backgroundColor: "#283443"
  }
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  backgroundColor: "#283443",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  "&> p": {
    color: theme.palette.warning.main,
    fontWeight: "bold",
    marginLeft: theme.spacing(1)
  }
}));

const Sidebar: React.FC = () => {
  const { state, toggleSidebar } = useNavigationState();
  const navigate = useNavigate();

  const [applicationMenu, setApplicationMenu] = React.useState<MenuItem | null>(null);
  const [activeApplication, setActiveApplication] = React.useState<Application>();
  const [showApplicationMenu, setShowApplicationMenu] = React.useState<boolean>(false);

  React.useEffect(() => {
    MessageBus.subscribe<Application>("app.load", (message, application) => {
      setActiveApplication(application);
      if (application.menu) {
        setApplicationMenu(application.menu);
        setShowApplicationMenu(true);
      }
    });

    MessageBus.subscribe("app.unload", () => {
      setShowApplicationMenu(false);
      setActiveApplication(undefined);
    });
  }, []);

  const handleNavigate = (path: string) => {
    if (
      !showApplicationMenu &&
      activeApplication?.path &&
      path.startsWith(activeApplication.path.value)
    ) {
      setShowApplicationMenu(true);
    } else {
      navigate(path);
      toggleSidebar();
    }
  };

  return (
    <Drawer anchor="left" open={state.sideBarOpen}>
      <Box>
        <DrawerHeader>
          <Typography>Nutrien Operations</Typography>
          <IconButton onClick={toggleSidebar}>
            <Close htmlColor="#A1A1A1" />
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List component="nav">
          <ListItem onClick={() => handleNavigate("/")}>
            <ListItemIcon>
              <ApplicationIcon name="grid" />
            </ListItemIcon>
            <ListItemText primary="Dashboard" />
          </ListItem>

          {showApplicationMenu && (
            <>
              <ListItem onClick={() => setShowApplicationMenu(false)}>
                <ListItemIcon>
                  <ApplicationIcon name="return" />
                </ListItemIcon>
                <ListItemText primary="My Applications" />
              </ListItem>
              <Divider />
            </>
          )}
        </List>
        {showApplicationMenu ? (
          <>
            <ApplicationMenu
              applicationId={activeApplication.applicationId}
              application={activeApplication}
              menu={applicationMenu}
              onNavigate={handleNavigate}
            />
            <Divider />
          </>
        ) : (
          <>
            {Object.keys(groupedApplications).map((key) => {
              return (
                <SideBarMenu
                  key={key}
                  applicationGroup={groupedApplications[key]}
                  onNavigate={handleNavigate}
                />
              );
            })}
          </>
        )}
      </Box>
      <Box>
        <SiteSelector />
      </Box>
    </Drawer>
  );
};

export default Sidebar;
