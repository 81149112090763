import { Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";

import config, {
  Application,
  SwaggerDocumentationConfigurationOfEnv
} from "@nutrien-operations/config";
import { WithPermissions } from "@packages/service-api";
import { Link } from "react-router-dom";

type Props = {
  application: Application;
};

const ApiDocumentationLink: React.FC<Props> = ({ application }) => {
  const [swaggerConfig, setSwaggerConfig] = useState<SwaggerDocumentationConfigurationOfEnv | null>(
    null
  );

  useEffect(() => {
    const nextConfigUrl = application?.swagger
      ? application.swagger[config.environment] ?? null
      : null;
    setSwaggerConfig(nextConfigUrl);
  }, [application]);

  const renderContent = () => (
    <Tooltip title="API Documentation">
      <Link
        to={swaggerConfig.url}
        target="_blank"
        onClick={(event) => {
          event.stopPropagation();
        }}
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 256 256">
          <path
            fill="#85ea2d"
            d="M127.999 249.895c-67.215 0-121.9-54.68-121.9-121.896C6.1 60.782 60.785 6.102 128 6.102c67.214 0 121.899 54.685 121.899 121.9c0 67.214-54.685 121.893-121.9 121.893"
          />
          <path
            fill="#173647"
            d="M127.999 12.202c63.954 0 115.797 51.842 115.797 115.797c0 63.952-51.843 115.797-115.797 115.797c-63.952 0-115.797-51.845-115.797-115.797S64.047 12.202 127.999 12.202m0-12.202C57.419 0 0 57.42 0 127.999s57.42 127.998 127.999 127.998S256 198.577 256 128C256 57.419 198.578 0 127.999 0"
          />
          <path
            fill="#173647"
            d="M80.598 86.619c-.394 4.38.146 8.909-.146 13.338c-.345 4.431-.887 8.811-1.773 13.192c-1.23 6.25-5.12 10.976-10.482 14.914c10.436 6.793 11.616 17.324 12.304 28.006c.345 5.76.197 11.567.788 17.276c.443 4.429 2.165 5.562 6.745 5.708c1.87.048 3.786 0 5.956 0v13.683c-13.535 2.313-24.708-1.525-27.467-12.992c-.887-4.184-1.478-8.467-1.673-12.798c-.297-4.578.195-9.155-.148-13.732c-.985-12.553-2.61-16.785-14.618-17.376v-15.602a23.714 23.714 0 0 1 2.608-.443c6.596-.345 9.4-2.364 10.828-8.86c.69-3.641 1.084-7.333 1.23-11.074c.494-7.136.297-14.42 1.525-21.507C67.997 68.163 74.3 63.24 84.785 62.65c2.952-.149 5.955 0 9.35 0v13.98c-1.427.1-2.658.294-3.937.294c-8.515-.297-8.96 2.607-9.6 9.695m16.39 32.386h-.196c-4.923-.245-9.155 3.593-9.403 8.515c-.246 4.972 3.592 9.206 8.515 9.45h.59c4.875.296 9.056-3.447 9.352-8.319v-.491c.1-4.971-3.886-9.055-8.857-9.155m30.862 0c-4.774-.148-8.763 3.593-8.909 8.318c0 .297 0 .543.051.837c0 5.365 3.641 8.812 9.155 8.812c5.414 0 8.812-3.544 8.812-9.106c-.051-5.366-3.646-8.91-9.109-8.86m31.602 0c-5.02-.1-9.206 3.89-9.352 8.91a9.03 9.03 0 0 0 9.055 9.054h.1c4.528.788 9.106-3.592 9.402-8.858c.243-4.874-4.186-9.106-9.205-9.106m43.363.737c-5.711-.245-8.567-2.164-9.992-7.581a54.874 54.874 0 0 1-1.624-10.582c-.395-6.596-.346-13.241-.789-19.837c-1.033-15.651-12.352-21.114-28.794-18.41V76.92c2.607 0 4.626 0 6.645.049c3.495.048 6.153 1.379 6.496 5.268c.345 3.543.345 7.136.69 10.73c.692 7.139 1.083 14.372 2.314 21.41c1.085 5.809 5.07 10.14 10.04 13.684c-8.71 5.857-11.27 14.223-11.714 23.626c-.245 6.448-.394 12.944-.736 19.443c-.297 5.905-2.362 7.824-8.318 7.972c-1.674.05-3.298.198-5.169.297v13.93c3.495 0 6.694.196 9.892 0c9.942-.592 15.947-5.415 17.918-15.063a125.582 125.582 0 0 0 1.476-16.045c.343-4.923.297-9.894.788-14.766c.737-7.63 4.232-10.78 11.862-11.27c.739-.1 1.427-.246 2.118-.492v-15.604c-1.282-.149-2.17-.295-3.103-.346"
          />
        </svg>
      </Link>
    </Tooltip>
  );

  if (!swaggerConfig) {
    return null;
  }

  return swaggerConfig.permission ? (
    <WithPermissions permissions={swaggerConfig.permission}>{renderContent()}</WithPermissions>
  ) : (
    renderContent()
  );
};

export default ApiDocumentationLink;
