import React from "react";

import {
  Alert,
  Box,
  FormControl,
  ListSubheader,
  MenuItem,
  Select,
  Typography
} from "@mui/material";

import { WithLoader } from "@packages/theme-mui-v5";
import { Site, useGetSites, useUpdateSelectedSite, useUserProfile } from "@packages/service-api";

const SitePanel: React.FC = () => {
  const userProfile = useUserProfile();
  const { loading: sitesLoading, sites } = useGetSites({}, { size: 100 });
  const { updateSelectedSite, saving } = useUpdateSelectedSite();

  const [selected, setSelected] = React.useState<Site>();

  const userHasAccessAtHomeSite = React.useMemo(() => {
    return userProfile.permissions.some((p) => p.siteId === userProfile.siteId);
  }, [userProfile]);

  const currentSite = React.useMemo(() => {
    return userProfile && sites.find((s) => s.siteId === userProfile.preferences?.selectedSiteId);
  }, [userProfile, sites]);

  const [mySite, otherSites] = React.useMemo(() => {
    const sitesWithPermissions = userProfile.permissions.reduce<string[]>((siteIds, permission) => {
      if (userProfile.isSysAdmin || !siteIds.includes(permission.siteId)) {
        siteIds.push(permission.siteId);
      }

      return siteIds;
    }, []);

    const result = sites.reduce(
      (value, site) => {
        if (
          // is not sysAdmin
          !userProfile.isSysAdmin &&
          // doesn't have permissions at this site
          !sitesWithPermissions.includes(site.siteId) &&
          // is not the user's home site
          site.siteId !== userProfile.siteId
        ) {
          return value;
        }
        if (site.siteId === userProfile.siteId) {
          value[0] = site;
        } else {
          value[1].push(site);
        }

        return value;
      },

      [undefined, []] as [Site | undefined, Site[]]
    );

    // returns the match for mySite (result[0]) and the remainder sorted by siteName as result[0]
    return [result[0], result[1].sort((a, b) => a.siteName.localeCompare(b.siteName))];
  }, [userProfile, sites]);

  React.useEffect(() => {
    const siteId = userProfile.preferences?.selectedSiteId || userProfile.siteId;
    const site = sites.find((s) => s.siteId === siteId);

    const hasPermission =
      (site?.siteId === mySite?.siteId && userHasAccessAtHomeSite) ||
      otherSites.some((s) => s.siteId === selected?.siteId);

    if (hasPermission) {
      setSelected(site);
    }
  }, [sites, userProfile, mySite, otherSites, userHasAccessAtHomeSite]);

  const updateSiteSelection = async () => {
    await updateSelectedSite(selected.siteId);
  };

  React.useEffect(() => {
    if (!!selected && selected?.siteId !== userProfile.preferences?.selectedSiteId) {
      updateSiteSelection();
    }
  }, [selected]);

  // profile.preferences is null
  // preferences.preferredSelectedSiteId is null

  // user has no access
  // home site doesn't exists/isn't returned from /sites
  // user has some access at the own site
  // user has no access at their own site
  // user has some access to another site but not their home site
  // user has some access to their home site and another site

  const userHomeSite = userProfile.siteId;
  const userPreferredSite = userProfile.preferences?.selectedSiteId;
  const userHasNoAccess = userProfile.permissions.length === 0;
  const userHasAccessAtNonHomeSite = otherSites.length > 0;

  const renderAlert = () => {
    return (
      <Box pb={2}>
        {userHasNoAccess && (
          <Alert severity="error">
            <b>You do not have access to applications at any site</b>
          </Alert>
        )}

        {!userHasNoAccess &&
          !userHasAccessAtHomeSite &&
          userHasAccessAtNonHomeSite &&
          (!userPreferredSite || userHomeSite === userPreferredSite) && (
            <Alert severity="warning">
              You do not have access to applications at your home site. Please select a preferred
              site where you have application access.
            </Alert>
          )}
        {!userHasNoAccess &&
          userHasAccessAtHomeSite &&
          !userHasAccessAtNonHomeSite &&
          !!userPreferredSite &&
          userHomeSite !== userPreferredSite && (
            <Alert severity="warning">
              You do not have access to applications at your selected preferred site. Please select
              your home site as your preferred site
            </Alert>
          )}
      </Box>
    );
  };

  return (
    <WithLoader loading={sitesLoading || saving}>
      {renderAlert()}
      <Box pb={2}>
        <Typography>
          <b>Your Home Site</b>
        </Typography>
        <Typography>{mySite?.siteName || "Not Available"}</Typography>
      </Box>
      {otherSites.length > 0 && (
        <Box pb={2}>
          <Typography gutterBottom>
            <b>Your Preferred site</b>
          </Typography>
          {userHasNoAccess ? (
            <Typography gutterBottom>N/A</Typography>
          ) : (
            <FormControl>
              <Select
                size="small"
                displayEmpty
                value={currentSite?.siteName || ""}
                onChange={(e) => {
                  const site = sites.find((s) => s.siteId === e.target.value);
                  setSelected(site);
                }}
              >
                <MenuItem value="">Select your preferred site</MenuItem>
                {currentSite && <ListSubheader>My Current Site</ListSubheader>}
                {currentSite && (
                  <MenuItem value={currentSite?.siteName || ""}>{currentSite?.siteName}</MenuItem>
                )}

                {userHasAccessAtHomeSite && <ListSubheader>My Site</ListSubheader>}
                {userHasAccessAtHomeSite && (
                  <MenuItem selected={!selected} value={mySite.siteId}>
                    {mySite.siteName}
                  </MenuItem>
                )}
                <ListSubheader>Other Sites</ListSubheader>
                {otherSites.sort().map((site) => (
                  <MenuItem key={site.siteId} value={site.siteId}>
                    {site?.siteName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </Box>
      )}
    </WithLoader>
  );
};

export default SitePanel;
