import { Application, applications } from "@nutrien-operations/config";
import React from "react";
import ShiftSelectorWrapper from "./ShiftSelectorWrapper";
import MessageBus from "@packages/message-bus";

type Props = {
  /**
   * Component to display when the current application does not have shifts enabled
   */
  fallback: React.ReactNode;
};

const ShiftSelector: React.FC<Props> = ({ fallback }) => {
  const [currentApplication, setCurrentApplication] = React.useState<Application>();

  React.useEffect(() => {
    MessageBus.subscribe<Application>("app.load", (_message, application) => {
      if (application.name !== "@nutrien-operations/config") {
        setCurrentApplication(application);
      }
    });
  }, []);

  const shiftSelectorConfig = React.useMemo(() => {
    if (!currentApplication) {
      return;
    }

    return Object.values(applications).find(
      (application) => application.applicationId === currentApplication?.applicationId
    );
  }, [currentApplication]);

  if (shiftSelectorConfig?.shifts?.showSelector && currentApplication) {
    return (
      <ShiftSelectorWrapper
        applicationId={currentApplication.applicationId}
        config={currentApplication.shifts}
      />
    );
  }

  return <>{fallback}</>;
};

export default ShiftSelector;
