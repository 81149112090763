import React from "react";

import { NavigationContext } from "../components/NavigationProvider/NavigationProvider";
import { UserAccountTab } from "../components/UserProfileModal/UserProfileModal";

const useNavigationState = () => {
  const { setState, state } = React.useContext(NavigationContext);

  const toggleSidebar = () => {
    setState("sideBarOpen", !state.sideBarOpen);
  };

  const showAccountModal = (defaultTab = "Account") => {
    setState("defaultAccountModalTab", defaultTab as UserAccountTab);
    setState("accountModalOpen", true);
  };

  const hideAccountModal = () => {
    setState("defaultAccountModalTab", "Account");
    setState("accountModalOpen", false);
  };

  return {
    state,
    toggleSidebar,
    showAccountModal,
    hideAccountModal
  };
};

export default useNavigationState;
