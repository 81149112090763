import React from "react";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  CircularProgress,
  Typography
} from "@mui/material";

import {
  Application,
  Site,
  useGetSites,
  useUserProfile,
  useGetApplications,
  UserProfilePermission
} from "@packages/service-api";

type ModulesLeaf = Record<string, UserProfilePermission[]>;
type ApplicationsLeaf = Record<string, ModulesLeaf>;
type PermissionsTree = Record<string, ApplicationsLeaf>;

const PermissionsList: React.FC = () => {
  const profile = useUserProfile();

  const { permissions } = profile || {};
  const { sites, loading } = useGetSites({}, { size: 100 });
  const { applications, loading: applicationsLoading } = useGetApplications();

  const permissionsTree = React.useMemo(() => {
    return permissions.reduce<PermissionsTree>((result, permission) => {
      if (!result[permission.siteId]) {
        result[permission.siteId] = {};
      }
      if (!result[permission.siteId][permission.applicationId]) {
        result[permission.siteId][permission.applicationId] = {};
      }
      if (!result[permission.siteId][permission.applicationId][permission.module]) {
        result[permission.siteId][permission.applicationId][permission.module] = [];
      }

      result[permission.siteId][permission.applicationId][permission.module].push(permission);

      return result;
    }, {});
  }, [permissions]);

  const sitesMap = React.useMemo(() => {
    return sites.reduce<Record<string, Site>>((sites, site) => {
      sites[site.siteId] = site;
      return sites;
    }, {});
  }, [sites]);

  const applicationsMap = React.useMemo(() => {
    return applications.reduce<Record<string, Application>>((apps, app) => {
      apps[app.applicationId] = app;
      return apps;
    }, {});
  }, [applications]);

  return (
    <>
      {loading || applicationsLoading ? (
        <CircularProgress />
      ) : (
        <Box>
          {Object.keys(permissionsTree).length === 0 && (
            <Box py={2}>
              <Typography>You do not have permissions to any applications</Typography>
            </Box>
          )}
          {Object.keys(permissionsTree).map((siteId) => (
            <Box key={siteId} my={2}>
              <Typography variant="h4" gutterBottom>
                {sitesMap[siteId]?.siteName}
              </Typography>
              {Object.keys(permissionsTree[siteId]).map((applicationId) => {
                const app = applicationsMap[applicationId];
                const modules = permissionsTree[siteId][applicationId];

                return (
                  <Accordion key={applicationId}>
                    <AccordionSummary>
                      <Typography gutterBottom>
                        <b>{app.applicationName}</b>
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      {Object.keys(modules).map((module) => (
                        <Box key={module}>
                          <Typography variant="subtitle2" sx={{ textTransform: "uppercase" }}>
                            {module}
                          </Typography>
                          {modules[module].map((p) => (
                            <div key={p.permissionTypeId}>{p.name}</div>
                          ))}
                        </Box>
                      ))}
                    </AccordionDetails>
                  </Accordion>
                );
              })}
            </Box>
          ))}
        </Box>
      )}
    </>
  );
};

export default PermissionsList;
